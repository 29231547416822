.support-paper{
    position:absolute;
    top: 30%;
    left: 20%;
    padding: 50px 50px 20px 50px;
    border-radius: 20px !important;
    margin-bottom: 50px !important;
}

.support-accordian{
    margin-bottom: 20px;
    width: 60vw;
    border-radius: 10px !important;
}
.support-trouble{
    color: #0E5E6F;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    display: block;
    margin-bottom: 30px;
}
.support-heading{
    color: #0E5E6F;
    font-size: 40px;
    position: absolute;
    top: 17%;
    left: 40%;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
}

@media (max-width: 760px) {
    .support-paper{
        left: 10%;
        right: 10%;
    }
    .support-accordian{
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
    }
    .support-heading{
        font-size: 20px;
    }
}
@media (max-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .support-paper{
        left: 10%;
        right: 10%;
    }
    .support-accordian{
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
    }
    .support-heading{
        font-size: 20px;
    }
}
