@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 100px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
    background-color: transparent !important;
}

body::-webkit-scrollbar{
    display: none !important;
}



*{
    margin: 0;
    padding: 0;
}

.registerForm{
    /* width: fit-content; */
    padding: 10px 100px 50px;
    margin: auto;
    margin-top: 50px;
    align-self: center;
}
.MuiInputBase-root{
    margin-bottom: 10px;
    width: 400px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}
.submitBtn{
    width: 40%;
    margin: auto !important;
    margin-top: 50px !important;
}

.registerHead {
    padding: 30px;
    text-align: center;
    font-weight: bold;
    color: #044f6f;
}


@media (min-width: 445px) and (max-width : 500px){
    .registerForm{
        padding: 10px 25px 25px !important;
    }
}
@media (min-width: 500) and (max-width : 600px){
    .registerForm{
        padding: 10px 50px 50px !important;
    }
    .MuiInputBase-root{
        width:90vw !important;
    }
}
@media (min-width:360px) and (max-width : 640px){
    .registerForm{
        padding: 10px 10px 10px !important;
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        width: 90vw !important;
    }
    .MuiInputBase-root{
        width:90vw !important;
    }
}

.loginHead{
    padding: 30px;
    text-align: center;
    font-weight: bold;
    color: #044f6f;
}

.loginform-card{
    padding: 0px 40px;
    box-shadow: rgba(153, 242, 247, 0.169) 0px -23px 25px 0px inset, rgba(151, 236, 236, 0.15) 0px -36px 30px 0px inset, rgba(128, 250, 252, 0.1) 0px -79px 40px 0px inset, rgba(152, 235, 230, 0.06) 0px 2px 1px, rgba(129, 245, 249, 0.09) 0px 4px 2px, rgba(135, 224, 242, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important;
    /* width: 75%; */
    width:400px;
}
.loginForm{
    /* width: fit-content; */
    width: 65%;
    padding: 80px;
    /* margin: auto; */
    margin-left: 15%;
    margin-top: 7.5rem;
}
.loginForm .MuiInputBase-root{
    margin-bottom: 10px;
    width: 300px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}

.forgot-pass{
    text-decoration: none;
    color: #044f6f;
    font-weight: 500;
    display: block;
}


.submitBtn{
    width: 40%;
    /* margin-left: 20% !important; */
    /* margin-top: 50px !important; */
}
@media (max-width: 600px){
    .loginForm{
        width: 100%;
        padding: 20px;
    }
}
@media (max-width: 800px){
    .loginForm{
        width: 80%;
        padding: 50px;
    }
}
@media (max-width: 1400px){
    .loginForm{
        width: 70%;
        padding: 30px;
    }
}
@media (max-width: 1260px){
    .loginForm{
        width: 80%;
        padding: 20px;
    }
}
@media (max-width: 1100px){
    .loginForm{
        width: 90%;
        padding: 20px;
    }
}
@media (max-width: 960px){
    .loginForm{
        width: 100%;
        padding: 20px;
    }
}
@media (max-width: 865px){
    .loginForm{
        width: 60%;
        padding: 50px;
    }
}
@media (max-width: 800px){
    .loginForm{
        width: 100%;
        padding: 20px;
        margin-left: 0%;
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-nav{
    /* width: 100%; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    margin-bottom: 0.2rem;
}
.logo{
    margin-left: 100px !important;
    margin-bottom: 0.2rem;
}
.logo h2{
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#eee, rgb(5 86 110), rgb(5 100 300));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logo span{
    font-size: 3.5rem;
}
.menu-nav-link{
    align-self: center;
}
.login, .register{
    text-decoration: none;
    border: 1px solid grey;
    padding: 10px 30px;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(11, 45, 150);
    border-color: rgb(11, 95, 200);
    border-radius: 10px;
    width: 150px;
    text-align: center;
}
.login:hover, .register:hover{
    color: rgb(11, 45, 150);
    border-color: rgb(11, 95, 200);
}
.toggler{
    margin-bottom: 10px;
    margin-right: 50px;
    
}

.collapsed{
    border: none !important;
}
.navbar-collapse{
    background-color: white;
}
/* .collapse{
    width: 60px !important;
} */

/* --------------------------- responsive style ----------------- */
@media (max-width : 1080px) {
    
}

@media (max-width : 998px){
    
}


@media (max-width : 800px){
    .logo{
        margin-left: 35px !important;
    }
    .logo h2{
        font-size: 1.5rem !important;
    }
    .logo span{
        font-size: 2.5rem !important;
    }
    .login, .register{
        padding: 10px 30px;
        margin-right: 20px;
        font-size: 18px;
        width: 150px;
    }
    .toggler{
        margin-right: 20px;
    }
}

@media (max-width : 600px){
    .logo{
        margin-left: 20px !important;
    }
    .logo h2{
        font-size: 1rem !important;
    }
    .logo span{
        font-size: 2rem !important;
    }
    .login, .register{
        padding: 10px 30px;
        margin-right: 20px;
        font-size: 18px;
        width: 150px;
    }
    .toggler{
        margin-right: 50px;    
    }
    
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}
.registerComponent{
    /* background-image: "url(" + "../../assets/image3.jpg" + ")"; */
    color: #075a6b;
    /* padding: 50px 100px; */
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    display: grid;
    justify-content: center;
    padding: 50px;
}
.navbar{
  margin-bottom: 0px;
  height: 65px;
}
.carousel-section{
  background-color: white;
  width: 100vw;
}
.carousel-img{
  opacity: 0.8;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.carousel-caption p{
  text-align: left; 
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
}



.vertical-timeline-element-icon, .bounce-in {
  box-shadow: 0px 0px transparent;
  text-align: center;
}

.vertical-goals {
  --color: rgba(30, 30, 30);
  /* --bgColor: rgb(212, 247, 253); */
  --bgColor: white;
  min-height: 50vh;
  display: grid;
  align-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  color: var(--color);
  background: var(--bgColor);
}

ul {
  --row-gap: 2rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: var(--col-gap);
  -webkit-column-gap: var(--col-gap);
          column-gap: var(--col-gap);
  list-style: none;
  width: min(80%, 90%);
  margin-inline: auto;
}

/* card */
ul li {
  /* grid-column: 2; */
  --inlineP: 2rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  width: 250px;
  grid-template-rows: -webkit-min-content -webkit-min-content -webkit-min-content;
  grid-template-rows: min-content min-content min-content;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}
ul li:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* .caro, .caro img{
  transition: transform .2s;
}

.caro:hover, .caro img:hover{
  transform: scale(1.2);
} */

/* date */
.date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);
  text-align: center;
  background-color: var(--accent-color);
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  display: grid;
  place-content: center;
  position: relative;
  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}
/* date flap */
.date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 0 100%);
          clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}


/* title descr */
.title, .descr {
  /* background: var(--bgColor); */
  background-color: rgb(248, 248, 232);
  position: relative;
  padding-inline: 1.5rem;
}
.title {
  overflow: hidden;
  -webkit-padding-before: 1.5rem;
          padding-block-start: 1.5rem;
  -webkit-padding-after: 1rem;
          padding-block-end: 1rem;
  font-weight: 500;
}
.descr {
  -webkit-padding-after: 1.5rem;
          padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.title::before, .descr::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  -webkit-filter: blur(4px);
          filter: blur(4px);
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}
.title::before {
  bottom: calc(100% + 0.125rem);
}

.descr::before {
  z-index: -1;
  bottom: 0.25rem;
}
.vertical-timeline--two-columns .vertical-timeline-element-icon{
  /* background-color: #023979 !important; */
  background-color: white !important;
  /* border: 5px solid white; */
  border: 5px solid #075a6b;
}

.vertical-timeline::before{
  background-color: #075a6b;
}

@media (min-width:360px) and (max-width : 640px){
  .carousel-caption p{
    font-size: 10px;
  }
}
@media only screen and (max-width: 1172px) {
  .vertical-timeline-element-content {
    margin-left: 8%;
  }
}

@media (max-width: 772px) {
  .vertical-timeline-element-content {
    margin-left: 14%;
  }
  .carousel-caption p {
    font-size: 10px;
  }
  .vertical-goals {
    padding: 1rem;
  }
  ul{
    width: 400px;
  }
  ul li {
    width:200px;
  }
}
@media (max-width: 480px) {
  ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
    width: 70vw;
  }
  ul::before {
    grid-column: 2;
  }
  ul li:nth-child(odd) {
    grid-column: 1;
    width: 40vw;
    margin-left: 5%;
  }
  ul li:nth-child(even) {
    grid-column: 3;
    width: 40vw;
    margin-left: 5%;
  }

  /* start second card */
  ul li:nth-child(2) {
    grid-row: 2/4;
    width: 40vw;
    margin-left: 5%;
  }

  ul li:nth-child(odd) .date::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  ul li:nth-child(odd) .date::after {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}



* {
    margin: 0;
    padding: 0;
}

.footer {
    background-color: #004658;
    color: #fff;
}
.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 10px;
    position: relative;
    top: -1px;
    width: 100vw;
}
.footer-wave-path {
    /* fill: #fffff2; */
    fill: white;
}
.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    padding: 40px 15px 100px;
    position: relative;
}
.rpc{
    font-size: 25px;
    position: relative;
    bottom: 50px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-left: 2.5%;
}
.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
} 

.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill: #027b9a;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
}

.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

/* .footer-social-icon-svg {
    display: block;
} */

.footer-social-link.facebook {
    height: 26px;
    left: 0;
    top: 11px;
    width: 26px;
}
.footer-social-link.linkedin {
    height: 26px;
    left: 55px;
    top: 4px;
    width: 26px;
}
.footer-social-link.instagram {
    height: 26px;
    left: 120px;
    top: 11px;
    width: 26px;
}
.footer-social-link.youtube {
    height: 24px;
    left: 170px;
    top: 6px;
    width: 24px;
}
.social-media{
    color: white;
}

/* .footer-social-link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
} */

.footer-copyright {
    background-color: #027b9a;
    color: #fff;
    padding: 15px 30px;
    text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
}

.footer-copyright-text {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}
.footer-copyright-link:hover {
    color: #fff;
    text-decoration: none;
}


.playstore-btn{
    width: 120px;
    height: 40px;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 5px;
}
.footer-btn-content{
    position:absolute; 
    bottom:30px; 
    /* display:block;  */
    justify-content: space-around !important;
}

.footer-btn-content-span{
    display:block; 
    margin-bottom:5px;
    font-weight:600; 
    letter-spacing:1px;
}


/* Media Query For different screens */
@media (min-width: 1000px){
    .footer-content {
        width: 100%;
        padding: 40px 15px 150px;
        font-size: 15px;
    }
    .footer-wave-svg {
        height: 60px;
    }
    .footer-btn-content{
        margin-bottom: 30px;
    }
    .rpc{
        font-size: 25px;
    }
}
/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width:320px) and (max-width:479px)  { 
    .footer-wave-svg {
        height: 40px;
        padding-top: 50px;
    }
}

@media (max-width: 760px) {
    .footer-content {
        width: 100%;
        padding: 40px 15px 150px;
        font-size: 12px;
    }
    .footer-wave-svg {
        height: 50px;
    }
    .footer-content-column {
        width: 25%;
    }
    .footer-social-links {
        bottom: 0;
        height: 54px;
        position: absolute;
        left: 35%;
        width: 236px;
    }
    .footer-btn-content{
        margin-bottom: 30px;
    }
    .rpc{
        font-size: 15px;
    }
}
@media (max-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
        width: 100%;
        padding: 40px 15px 150px;
        font-size: 12px;
    }
    .footer-wave-svg {
        height: 40px;
    }
    .footer-content-column {
        width: 25%;
    }
    .footer-social-links {
        bottom: 0;
        height: 54px;
        position: absolute;
        left: 25%;
        width: 236px;
    }
    .footer-btn-content{
        margin-bottom: 30px;
    }
    .rpc{
        font-size: 15px;
    }
}
@media (min-width:360px) and (max-width : 640px){
    .footer-wave-svg {
        height: 40px;
        padding-top: 50px;
    }
}
    
  
/* .upload-section{
    background-color: ;
} */

.upload-container{
    padding: 100px 50px 50px;
}
.data-img img{
    padding: 50px;
}
.upload-paper{
    padding: 60px;
    height: 350px;
    background-color: rgb(190, 255, 245) !important;
}
.about{
    /* background-color: white; */
    opacity: 0.5;
}
.about h1{
    color: white;
    position: relative;
    top: -100px;
    left: 100px;
    /* z-index: 10000; */
}
.school-icon, .tpo-icon{
    margin: auto;
    color: #004658;
}
.upload-paper p{
    color: gray !important;
    text-align: center;
    padding: 30px;
}
.upload-btn, .submit-btn{
    background-color: rgb(190, 255, 245) !important;
    border: 1px solid #004658 !important;
    color: #004658 !important;
}
.upload-btn{
    margin-right: 20px !important;
}
.upload-btn:hover, .submit-btn:hover{
    background-color: #004658 !important;
    color: white !important;
}
.sample-excel-sheets{
    position: relative;
    top: 10vh;
    margin-top: 50px;
}
.sample-student-sheets, .sample-tpo-sheets{
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border: solid 1px green;
}
.sample-student-sheets p, .sample-tpo-sheets p{
    font-size: 16px;
    color: gray;
    font-weight: 600;
    position: relative;
    top: 5px;
}










/* Profile image --------------------------------- */

.inst-background-img{
    width: 100vw;
    /* opacity: 0.8; */
    /* z-index: -10000; */
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-nav{
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    /* margin-bottom: 0.2rem !important; */
    height: 65px;
    position: fixed;
    top: 0%;
    z-index: 5;
    background-color: white;
}
.logo h2{
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#eee, rgb(5 86 110), rgb(5 100 300));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logo span{
    font-size: 3.5rem;
}
.menu-link{
    /* align-self: center; */
    float: right;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    position: absolute;
    /* top: -133%; */
    top: 20%;
    right: 5%;
    display: inline-flex;
}
.profile{
    background: -webkit-linear-gradient(#eee, rgb(5 86 110), rgb(5 100 300));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left : 20px;
    margin-right: 20px;
    text-decoration: none;
    font-weight: 500;
    font-size: 25px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    position: relative;
    top: 0%;
    left: -60%;
}
.profile-img{
    display: inline-flex !important;
}
.student-notif-badge{
    position:absolute;
    top: -26px;
    right: 8px;
    color:'#0E5E6F';
}

.student-notif-badge .css-to4x1n-MuiBadge-badge{
    min-width: 10px !important;
    width: 15px !important;
    height: 15px !important;
}
.profile-pic-img{
    position: absolute;
    /* top: -250%; */
    bottom: -10%;
    left: 50%;
}
.institution-profile-pic-img{
    left: 70%;
}
.notification-avatar{
    position: absolute;
    bottom: -10%;
    right: 10px;
}
.notification-avatar-tpo{
    position: absolute;
    bottom: -10%;
    left: 70%;
}
.notification-avatar-admin{
    position: absolute;
    bottom: -10%;
    left: 80%;
}
.notification-avatar-institution{
    position: absolute;
    bottom: -10%;
    /* left: 80%; */
}

.institution-name{
    font-weight: 600;
    color: #0E5E6F;
    font-size: 24px;
    margin-right: 10px;
    align-items: center;
}
.institution-name:hover{
    color: #0E5E6F;
}

/* --------------------------- responsive style ----------------- */
@media (max-width : 1080px) {
    
}

@media (max-width : 998px){
    
}


@media (max-width : 798px){
    
}

@media (max-width : 520px){
    
}
body{
    background-color: white;
}
.child-component{
    margin-top: 60px;
}
.MuiAppBar-colorPrimary{
    background-color: transparent !important;
    width: 70px !important;
    position: absolute !important;
    left: 0px !important;
}
header{
    box-shadow: none !important;
}
.MuiIconButton-colorInherit{
    color: rgb(3, 64, 83) !important;
    position: fixed !important;
}
.MuiDrawer-paperAnchorLeft{
    /* top : 8% !important; */
    top : 65px !important;
}
.MuiListItemIcon-root{
    /* color: #ecfbff !important; */
    color: white !important;
}
.SideNavComponent-toolbar-13{
    display: none !important;
}
/* .user-detail{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 50px !important;
} */
.user-img{
    /* margin: 50px 0 50px 10px; */
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
.user-detail span{
    text-align: center;
}
.user-menu a{
    text-decoration: none;
    /* color: #ecfbff; */
    color: white;
}
.child-component{
    margin: 60px 5% 20px;
    width: 95%;
}
/* .divider{
    background-color: white !important;
} */
.MuiDrawer-paper::-webkit-scrollbar{
    display: none !important;
}
.user-head{
    padding: 30px;
    text-align: center;
    font-weight: bold;
    color: rgb(4, 79, 111);
}


.forgot-pass-card{
    padding: 40px !important;
    box-shadow: rgba(153, 242, 247, 0.17) 0px -23px 25px 0px inset, rgba(151, 236, 236, 0.15) 0px -36px 30px 0px inset, rgba(128, 250, 252, 0.1) 0px -79px 40px 0px inset, rgba(152, 235, 230, 0.06) 0px 2px 1px, rgba(129, 245, 249, 0.09) 0px 4px 2px, rgba(135, 224, 242, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important;
    /* width: 400px; */
}
.forgot-pass-form{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 80px;
    margin: auto;
    margin-top: 5rem;
}
.MuiInputBase-root{
    margin-bottom: 10px;
    width: 300px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}

.forgot-pass{
    text-decoration: none;
    color: rgb(4, 79, 111);
    font-weight: 500;
}


.sendBtn{
    width: 40%;
    margin: auto !important;
    margin-top: 50px !important;
}
.head{
    padding: 30px;
    text-align: center;
    font-weight: bold;
    color: rgb(4, 79, 111);
}


.reset-pass-card{
    padding: 40px !important;
    box-shadow: rgba(153, 242, 247, 0.17) 0px -23px 25px 0px inset, rgba(151, 236, 236, 0.15) 0px -36px 30px 0px inset, rgba(128, 250, 252, 0.1) 0px -79px 40px 0px inset, rgba(152, 235, 230, 0.06) 0px 2px 1px, rgba(129, 245, 249, 0.09) 0px 4px 2px, rgba(135, 224, 242, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important;
    /* width: 400px; */
}
.reset-pass-form{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 80px;
    margin: auto;
    margin-top: 5rem;
}
.MuiInputBase-root{
    margin-bottom: 10px;
    width: 300px;
}

/* .reset-pass{
    text-decoration: none;
    color: rgb(4, 79, 111);
    font-weight: 500;
} */


.sendBtn{
    width: 40%;
    margin: auto !important;
    margin-top: 50px !important;
}
.profile-img-section{
    background-color: #251B37;
    /* background-color: #120664; */
    width: 100vw;
    height: 40vh;
}

.profile-img{
    background-color: white;
    height: 150px;
    width: 150px;
    margin-top: 100px;
}
.profile-name{
    color: white;
    height: 150px;
    margin-top: 100px;
    padding: 40px;
    font-size: 20px;
    font-weight: 500;
}
.profile-name span{
    display: block;
}
.profile-list a{
    text-decoration: none;
    color: #251B37;
    font-size: 20px;
    font-weight: 500;
}
.active{
    background-color: #251B37;
    color: white;
}
.profile-list-item, .skill-list-item{
    grid-column: 1 !important;
}
.basic-details, .account-details, .internship-details, .education-details, .extra-curricular-activity-details, .certification-details, .tech-skills-details, .project-details{
    position: absolute;
    top: 30vh;
    width: 900px;
}
.basic-details p, .account-details p, .internship-details p, .education-details p, .extra-curricular-activity-details p, .certification-details p, .tech-skills-details p, .project-details p{
    font-size: 18px;
    font-weight: 500;
    color: #444343;
    padding: 20px;
}
.basic-details .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
    padding: 0px !important;
}
.basic-details .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    margin-bottom: 0px;
}

.basic-details .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 0px;
}
.account-details .MuiTableRow-root{
    padding: 15px !important;
}
.activity-edit-btn, .project-edit-btn, .certification-edit-btn{
    margin-top: 35px !important;
}
.activity-edit-btn{
    height: 30px;
    float: left;
    color:'#0E5E6F' !important;
}
.intern-edit-btn{
    margin-top: 50px !important;
}
.experience-btn, .activity-btn, .certification-btn, .add-project-btn, .add-skill-btn{
    /* float: center; */
    /* margin: 30px 50px !important; */
    margin: 5% 40% !important;
}
.add-exp-dialog, .add-activity-dialog, .add-certification-dialog, .add-skill-dialog{
    padding: 50px 100px !important;
    align-content: center;
}
.add-exp-dialog .organization-name .MuiInputBase-root, .add-exp-dialog .job-title-name .MuiInputBase-root, .add-exp-dialog .location-name .MuiInputBase-root, .add-exp-dialog .sector-name .MuiInputBase-root, .add-activity-dialog .organization-name .MuiInputBase-root, .add-activity-dialog .position-title-name .MuiInputBase-root{
    width: 380px !important;
}
.progress-bar{
    background-color: #0E5E6F;
    color: aliceblue;
}
.education-edit-section, .basic-info-edit-section{
    display: flex !important;
    justify-content: space-between;
}
.education-edit-section p, .basic-info-edit-section p{
    margin-top: 20px;
}
.education-edit-btn, .basic-info-edit-btn{
    margin-top: 20px !important;
    height: 50px;
    padding: 0px 50px !important;
}
.attach-file-btn{
    width: 400px;
    height: 55px;
    display: flex !important;
    justify-content: space-between !important;
}
.certification-save-btn{
    /* width: 300px; */
    margin: 30px 0px !important;
}
.skill-btn{
    margin-left: 40% !important;
}
.add-project-btn{
    margin: 30px 25px !important;
}
.intern-duration-date-section{
    display: flex;
}
.intern-duration-date-section .date-selector{
    margin: 10px 20px 10px 0px;
}
.intern-duration-date-section .date-selector .MuiInputBase-root{
    width: 180px !important;
}

.stepper-box {
    width: 100%;
    margin: auto;
    padding: 50px 0px;
}
.steps{
    display: flex;
}
.step-data{
    width: 100%;
    margin: 5%;
}
.select-data{
    margin: 50px auto 50px;
    display: flex;
    justify-content: space-between;
    padding: 50px 50px 50px 30px;
}
.select-data-2{
    /* margin: 50px auto 50px; */
    padding: 50px 30px 50px 20px;
}
.step-btn-row{
    padding: 50px 50px 100px 50px;
}

.step-1-btn{
    display: block;
}
.step-1-data{
    display: flex;
    justify-content : space-between;
    padding: 50px 20px;
    margin: 50px auto 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.step-1-img{
    margin-left: 5%;
}
.step-2-img{
    margin-left: 27%;
}
.step1-input-area{
    margin: 3% 0px 0px 10%;
}
.step1-input{
    margin: 5px !important;
}
.step-1-data .dropdown{
    margin-top: 10px !important;
    margin-left: 5px !important;
    border-bottom: 1.5px solid gray;
    width: 300px;
    /* background-color: white !important; */
}
.step-data .MuiInputBase-root{
    width: 300px !important;
}
/* for multiple profile designation with respective packages */

.profile-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .package-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .step2-input-area .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 220px !important;
}
.profile-add-btn{
    background-color: #075a6b !important;
    color: white;
    margin: 25px auto auto 25px !important;
}
.profile-save-btn{
    border-color: #03506f !important;
    color: #03506f !important;
    float: right;
}


.btn-light{
    background-color: white !important;
    border: none;
    color: gray;
}
.step2-input-area{
    /* width: 450px; */
    padding: 70px 0px 0px 20px !important;
}
.batch-select, .course-select, .branch-select{
    margin-top: 20px;
}
.step2-input-area .dropdown{
    border-bottom: 1.5px solid #044f6f;
}
.dropdown{
    margin-bottom: 15px;
}

.form-check-input:checked{
    background-color: #03506f;
    border-color: #03506f;
}
.step-3-data {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(4, 79, 111) !important;
}
.step-3-data p{
    padding: 50px;
}
.next-step-btn, .back-step-btn{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 25% !important;
}
.successfully-created p{
    font-size: 18px;
    font-weight: 500;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    grid-column: 1 !important;
}
.skillbar{
    height: 40px !important;
}
.skillbar-title{
    width: 180px !important;
}
.skillbar-bar{
    position: unset !important;
}
.skillbar-title span{
    padding: 0px !important;
    text-align: center;
    margin-top: 8%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    font-size: 16px;
    vertical-align: middle;
}
.skillbar-percent{
    display: none !important;
}
.drive-history-table .css-f3jnds-MuiDataGrid-columnHeaders{
    /* background-color: #2F2F2F; */
    background-color: #03374d;
    color: white;
}
.drive-history-table .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{
    color: white;
}

/* shortlisted students css */
.profile-heading{
    font-size: 18px;
    font-weight: bold;
    color: #03374d;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    background-color: rgb(191, 251, 251);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
}
a{
    text-decoration: none !important;
}
.show-result-btn{
    /* background-color: #03374d !important; */
    background-color: #DFF6FF !important;
    color: #03374d !important;
    /* border-color: #03374d !important; */
    border: none !important;
}
.add-result-btn{
    color: #03374d !important;
    /* border-color: #03374d !important; */
    background-color: rgb(254, 235, 200) !important;
    border: none !important;
}
.tpo-detail{
    background-color: white;
    padding: 10px 5px 0px 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    width: 101%;
}
.drive-feed{
    margin-top: 20px;
    /* border: 1px solid gray; */
    border-radius: 10px;
    padding: 10px 50px 10px 60px;
    background-color: #D6E5E3;
    /* background-color: #2701fd; */
}
.company-logo{
    background-color: white;
    padding: 30px;
    width: 250px;
    border-radius: 5px;
}
.company-logo img{
    width: 190px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 30px !important;
}
.drive-detail{
    /* padding: 50px; */
    margin: auto;
}
.drive-detail .comp-name, .skills-box .skill-requirement-text {
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    font-size: 24px;
    text-align: left !important;
    margin-left: 2%;
}
.skills-box .skill-requirement-text{
    font-size: 20px;
}
.skills-box{
    background-color: white;
    width: 250px;
}
.skills-box p, .hiring-flow-heading, .drive-feed p{
    text-align: center !important;
    font-size: 18px;
    font-weight: bold;
    color: #044f6f;
    letter-spacing: 1px;
}
.tpo-detail p{
    text-align:left !important;
}
.hiring-step-box{
    font-size: 16px;
}

.skills-box .profile-designation-name{
    font-size: 16px;
    color: rgb(143, 20, 205);
    text-align: left !important;
}
.feed-tpo-bar{
    border-radius: 50%;
    margin-bottom: 5px;
    background-size: cover;
    height: 50px;
    width: 50px;
}
/* .tpo-detail{
    background-color: white;
    padding: 10px 5px;
    margin-bottom: 20px;
    border-radius: 5px;
} */
/* .drive-feed{
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px 50px 10px 50px;
    background-color: #D6E5E3;
} */
.drive-feed-short-details{
    border-radius: 10px;
    padding: 20px;
    background-color: #D6E5E3;
}
.company-logo{
    background-color: white;
    padding: 30px;
    width: 250px;
    border-radius: 5px;
}
.company-logo img{
    width: 190px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 50px;
}
.company-logo-short-detail{
    background-color: white;
    padding: 10px;
    width: 160px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 5px;
}
.company-logo-short-detail img{
    width: 140px;
    height: 140px;
    margin: auto;
}
.drive-detail{
    margin: auto;
}
.skills-box{
    background-color: white;
    width: 250px;
}
/* .skills-box p, .hiring-flow-heading, .drive-feed p{
    text-align: center !important;
    font-size: 18px;
    font-weight: bold;
    color: #044f6f;
    letter-spacing: 1px;
    padding-top : 10px;
} */
.tpo-detail p{
    text-align:left !important;
}
.hiring-step-box{
    font-size: 16px;
}
.apply-row{
    padding: 20px 0px 0px 0px;
    background-color: white;
    border-radius: 5px;
}
.apply-row-show{
    margin-bottom: 20px;
    padding: 10px 0px;
}
.eligibility-msg .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    /* width: 40vw; */
    display: inline-block;
}
.eligibility-msg{
    /* width: 470px !important; */
    display: inline-flex;
    justify-content: space-around;
    margin: -12px 3% 30px 5% !important;
    align-content: center;
    background-color: #e0f9aa3d !important;
}
/* .eligibility-msg .css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin-top: -12px;
} */


.eligibility-msg .css-i4bv87-MuiSvgIcon-root{
    margin: auto;
    margin-bottom: 10px;
}
.eligibility-msg .comp-name, .eligibility-msg .drive-heading {
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    font-size: 24px;
    text-align: center !important;
    font-weight: bold;
    color: #044f6f;
    letter-spacing: 1px;
}

.eligibility-msg .drive-heading{
    font-size: 18px;
    margin-top: 10px !important;
}
.eligibility-msg .profile-designation-name{
    color: #044f6f;
    text-align: center !important;
}
.profile-designation-error{
    background-color : white !important;
    margin-top:-20px;
    margin-left:-20px;
    margin-bottom: 20px;
}
.image-upload .MuiOutlinedInput-notchedOutline{
    display: none !important;
}

.MuiGrid-spacing-xs-3{
    margin: 0px !important;
    padding-right: 20px;
}
.MuiGrid-grid-xs-3{
    background-color: #334d6a;
    color: white;
    border-radius: 10px;
    max-width: 24% !important;
    margin-right: 19px !important;
    margin-left: -10px !important;
}
.MuiGrid-grid-xs-9{
    background-color: aliceblue;
    border-radius: 10px;
    /* margin-left: 10px; */
}
.resume-header{
    background-color: aliceblue;
    border-radius: 10px;
    margin-right: 10px !important;
}
.makeStyles-ParentResumePaper-57{
    background-color: #deecf8 !important;
}
.makeStyles-ParentResumeModel-57{
    background-color: #deecf8 !important;
}

.resume .MuiInputLabel-shrink{
    -webkit-transform: translate(14px, -9px) scale(0.75) !important;
            transform: translate(14px, -9px) scale(0.75) !important;
    background-color: white;
}
*{
    margin: 0;
    padding: 0;
}
body{
    background-color: white;
}

/* Dashboard Children */

.child-component{
    margin-top: 60px;
}


/* Add institution  */
.registerF{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 100px 50px;
    margin: auto;
    margin-top: 50px;
}
.MuiInputBase-root{
    margin-bottom: 10px;
    width: 400px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}
.submitBtn{
    width: 30%;
    margin: auto !important;
    margin-top: 50px !important;
}

.registerHead {
    /* padding: 30px;
    text-align: center;
    font-weight: bold; */
    color: #044f6f;
    margin: 30px auto 50px auto;
}

/* joining request */
.request-paper{
    padding: 50px;
    width: 90%;
    margin: auto;
    margin-top: 50px;

}

/* DASHBOARD CONTENT  */
.summarized-detail{
    background-color: white;
    padding: 20px;
}


/* manage users styles */
.user-role-heading{
    font-size: 18px;
    font-weight: bold;
    color: #03374d;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    background-color: rgb(191, 251, 251);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}
.outer-paper{
    padding: 20px 50px 50px 50px;
    margin-top: 50px;
}
.inner-paper{
    padding: 30px;
    margin-top: 50px;
}

.users-delete-btn{
    float: right;
    background-color: #044f6f !important;
}

ul li:nth-child(even){
    grid-column: 1;
}
.pie-chart-list-items{
    color:#344D67; 
    display:flex;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 600 !important;
}
.pie-chart-list-content{
    width:15px;
    height:15px;  
    border-radius:5px; 
    display:flex !important;
    margin-right: 10px;
}
.pie-chart-details{
    position: absolute;
    top:40%;
    right:0%;
}
.InputContainer .InputElement{
    font-size: 20px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    color: rgb(154, 154, 154) !important;
    font-weight: 600;
}
.support-paper{
    position:absolute;
    top: 30%;
    left: 20%;
    padding: 50px 50px 20px 50px;
    border-radius: 20px !important;
    margin-bottom: 50px !important;
}

.support-accordian{
    margin-bottom: 20px;
    width: 60vw;
    border-radius: 10px !important;
}
.support-trouble{
    color: #0E5E6F;
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    display: block;
    margin-bottom: 30px;
}
.support-heading{
    color: #0E5E6F;
    font-size: 40px;
    position: absolute;
    top: 17%;
    left: 40%;
    text-align: center;
    font-weight: 600;
    letter-spacing: 1px;
}

@media (max-width: 760px) {
    .support-paper{
        left: 10%;
        right: 10%;
    }
    .support-accordian{
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
    }
    .support-heading{
        font-size: 20px;
    }
}
@media (max-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .support-paper{
        left: 10%;
        right: 10%;
    }
    .support-accordian{
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
    }
    .support-heading{
        font-size: 20px;
    }
}

