.loginHead{
    padding: 30px;
    text-align: center;
    font-weight: bold;
    color: #044f6f;
}

.loginform-card{
    padding: 0px 40px;
    box-shadow: rgba(153, 242, 247, 0.169) 0px -23px 25px 0px inset, rgba(151, 236, 236, 0.15) 0px -36px 30px 0px inset, rgba(128, 250, 252, 0.1) 0px -79px 40px 0px inset, rgba(152, 235, 230, 0.06) 0px 2px 1px, rgba(129, 245, 249, 0.09) 0px 4px 2px, rgba(135, 224, 242, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important;
    /* width: 75%; */
    width:400px;
}
.loginForm{
    /* width: fit-content; */
    width: 65%;
    padding: 80px;
    /* margin: auto; */
    margin-left: 15%;
    margin-top: 7.5rem;
}
.loginForm .MuiInputBase-root{
    margin-bottom: 10px;
    width: 300px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}

.forgot-pass{
    text-decoration: none;
    color: #044f6f;
    font-weight: 500;
    display: block;
}


.submitBtn{
    width: 40%;
    /* margin-left: 20% !important; */
    /* margin-top: 50px !important; */
}
@media (max-width: 600px){
    .loginForm{
        width: 100%;
        padding: 20px;
    }
}
@media (max-width: 800px){
    .loginForm{
        width: 80%;
        padding: 50px;
    }
}
@media (max-width: 1400px){
    .loginForm{
        width: 70%;
        padding: 30px;
    }
}
@media (max-width: 1260px){
    .loginForm{
        width: 80%;
        padding: 20px;
    }
}
@media (max-width: 1100px){
    .loginForm{
        width: 90%;
        padding: 20px;
    }
}
@media (max-width: 960px){
    .loginForm{
        width: 100%;
        padding: 20px;
    }
}
@media (max-width: 865px){
    .loginForm{
        width: 60%;
        padding: 50px;
    }
}
@media (max-width: 800px){
    .loginForm{
        width: 100%;
        padding: 20px;
        margin-left: 0%;
    }
}