.image-upload .MuiOutlinedInput-notchedOutline{
    display: none !important;
}

.MuiGrid-spacing-xs-3{
    margin: 0px !important;
    padding-right: 20px;
}
.MuiGrid-grid-xs-3{
    background-color: #334d6a;
    color: white;
    border-radius: 10px;
    max-width: 24% !important;
    margin-right: 19px !important;
    margin-left: -10px !important;
}
.MuiGrid-grid-xs-9{
    background-color: aliceblue;
    border-radius: 10px;
    /* margin-left: 10px; */
}
.resume-header{
    background-color: aliceblue;
    border-radius: 10px;
    margin-right: 10px !important;
}
.makeStyles-ParentResumePaper-57{
    background-color: #deecf8 !important;
}
.makeStyles-ParentResumeModel-57{
    background-color: #deecf8 !important;
}

.resume .MuiInputLabel-shrink{
    transform: translate(14px, -9px) scale(0.75) !important;
    background-color: white;
}