.MuiAppBar-colorPrimary{
    background-color: transparent !important;
    width: 70px !important;
    position: absolute !important;
    left: 0px !important;
}
header{
    box-shadow: none !important;
}
.MuiIconButton-colorInherit{
    color: rgb(3, 64, 83) !important;
    position: fixed !important;
}
.MuiDrawer-paperAnchorLeft{
    /* top : 8% !important; */
    top : 65px !important;
}
.MuiListItemIcon-root{
    /* color: #ecfbff !important; */
    color: white !important;
}
.SideNavComponent-toolbar-13{
    display: none !important;
}
/* .user-detail{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-bottom: 50px !important;
} */
.user-img{
    /* margin: 50px 0 50px 10px; */
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}
.user-detail span{
    text-align: center;
}
.user-menu a{
    text-decoration: none;
    /* color: #ecfbff; */
    color: white;
}
.child-component{
    margin: 60px 5% 20px;
    width: 95%;
}
/* .divider{
    background-color: white !important;
} */
.MuiDrawer-paper::-webkit-scrollbar{
    display: none !important;
}