*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-nav{
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    /* margin-bottom: 0.2rem !important; */
    height: 65px;
    position: fixed;
    top: 0%;
    z-index: 5;
    background-color: white;
}
.logo h2{
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#eee, rgb(5 86 110), rgb(5 100 300));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logo span{
    font-size: 3.5rem;
}
.menu-link{
    /* align-self: center; */
    float: right;
    height: fit-content;
    width: fit-content;
    padding: 0px;
    position: absolute;
    /* top: -133%; */
    top: 20%;
    right: 5%;
    display: inline-flex;
}
.profile{
    background: -webkit-linear-gradient(#eee, rgb(5 86 110), rgb(5 100 300));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left : 20px;
    margin-right: 20px;
    text-decoration: none;
    font-weight: 500;
    font-size: 25px;
    font-variant: small-caps;
    position: relative;
    top: 0%;
    left: -60%;
}
.profile-img{
    display: inline-flex !important;
}
.student-notif-badge{
    position:absolute;
    top: -26px;
    right: 8px;
    color:'#0E5E6F';
}

.student-notif-badge .css-to4x1n-MuiBadge-badge{
    min-width: 10px !important;
    width: 15px !important;
    height: 15px !important;
}
.profile-pic-img{
    position: absolute;
    /* top: -250%; */
    bottom: -10%;
    left: 50%;
}
.institution-profile-pic-img{
    left: 70%;
}
.notification-avatar{
    position: absolute;
    bottom: -10%;
    right: 10px;
}
.notification-avatar-tpo{
    position: absolute;
    bottom: -10%;
    left: 70%;
}
.notification-avatar-admin{
    position: absolute;
    bottom: -10%;
    left: 80%;
}
.notification-avatar-institution{
    position: absolute;
    bottom: -10%;
    /* left: 80%; */
}

.institution-name{
    font-weight: 600;
    color: #0E5E6F;
    font-size: 24px;
    margin-right: 10px;
    align-items: center;
}
.institution-name:hover{
    color: #0E5E6F;
}

/* --------------------------- responsive style ----------------- */
@media (max-width : 1080px) {
    
}

@media (max-width : 998px){
    
}


@media (max-width : 798px){
    
}

@media (max-width : 520px){
    
}