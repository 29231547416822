/* .tpo-detail{
    background-color: white;
    padding: 10px 5px;
    margin-bottom: 20px;
    border-radius: 5px;
} */
/* .drive-feed{
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px 50px 10px 50px;
    background-color: #D6E5E3;
} */
.drive-feed-short-details{
    border-radius: 10px;
    padding: 20px;
    background-color: #D6E5E3;
}
.company-logo{
    background-color: white;
    padding: 30px;
    width: 250px;
    border-radius: 5px;
}
.company-logo img{
    width: 190px;
    height: fit-content;
    margin-bottom: 50px;
}
.company-logo-short-detail{
    background-color: white;
    padding: 10px;
    width: 160px;
    height: fit-content;
    border-radius: 5px;
}
.company-logo-short-detail img{
    width: 140px;
    height: 140px;
    margin: auto;
}
.drive-detail{
    margin: auto;
}
.skills-box{
    background-color: white;
    width: 250px;
}
/* .skills-box p, .hiring-flow-heading, .drive-feed p{
    text-align: center !important;
    font-size: 18px;
    font-weight: bold;
    color: #044f6f;
    letter-spacing: 1px;
    padding-top : 10px;
} */
.tpo-detail p{
    text-align:left !important;
}
.hiring-step-box{
    font-size: 16px;
}
.apply-row{
    padding: 20px 0px 0px 0px;
    background-color: white;
    border-radius: 5px;
}
.apply-row-show{
    margin-bottom: 20px;
    padding: 10px 0px;
}
.eligibility-msg .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    /* width: 40vw; */
    display: inline-block;
}
.eligibility-msg{
    /* width: 470px !important; */
    display: inline-flex;
    justify-content: space-around;
    margin: -12px 3% 30px 5% !important;
    align-content: center;
    background-color: #e0f9aa3d !important;
}
/* .eligibility-msg .css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin-top: -12px;
} */


.eligibility-msg .css-i4bv87-MuiSvgIcon-root{
    margin: auto;
    margin-bottom: 10px;
}
.eligibility-msg .comp-name, .eligibility-msg .drive-heading {
    font-variant: small-caps;
    font-size: 24px;
    text-align: center !important;
    font-weight: bold;
    color: #044f6f;
    letter-spacing: 1px;
}

.eligibility-msg .drive-heading{
    font-size: 18px;
    margin-top: 10px !important;
}
.eligibility-msg .profile-designation-name{
    color: #044f6f;
    text-align: center !important;
}
.profile-designation-error{
    background-color : white !important;
    margin-top:-20px;
    margin-left:-20px;
    margin-bottom: 20px;
}