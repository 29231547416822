.drive-history-table .css-f3jnds-MuiDataGrid-columnHeaders{
    /* background-color: #2F2F2F; */
    background-color: #03374d;
    color: white;
}
.drive-history-table .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{
    color: white;
}

/* shortlisted students css */
.profile-heading{
    font-size: 18px;
    font-weight: bold;
    color: #03374d;
    font-variant: small-caps;
    background-color: rgb(191, 251, 251);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
}
a{
    text-decoration: none !important;
}
.show-result-btn{
    /* background-color: #03374d !important; */
    background-color: #DFF6FF !important;
    color: #03374d !important;
    /* border-color: #03374d !important; */
    border: none !important;
}
.add-result-btn{
    color: #03374d !important;
    /* border-color: #03374d !important; */
    background-color: rgb(254, 235, 200) !important;
    border: none !important;
}