*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-nav{
    /* width: 100%; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    margin-bottom: 0.2rem;
}
.logo{
    margin-left: 100px !important;
    margin-bottom: 0.2rem;
}
.logo h2{
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#eee, rgb(5 86 110), rgb(5 100 300));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logo span{
    font-size: 3.5rem;
}
.menu-nav-link{
    align-self: center;
}
.login, .register{
    text-decoration: none;
    border: 1px solid grey;
    padding: 10px 30px;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(11, 45, 150);
    border-color: rgb(11, 95, 200);
    border-radius: 10px;
    width: 150px;
    text-align: center;
}
.login:hover, .register:hover{
    color: rgb(11, 45, 150);
    border-color: rgb(11, 95, 200);
}
.toggler{
    margin-bottom: 10px;
    margin-right: 50px;
    
}

.collapsed{
    border: none !important;
}
.navbar-collapse{
    background-color: white;
}
/* .collapse{
    width: 60px !important;
} */

/* --------------------------- responsive style ----------------- */
@media (max-width : 1080px) {
    
}

@media (max-width : 998px){
    
}


@media (max-width : 800px){
    .logo{
        margin-left: 35px !important;
    }
    .logo h2{
        font-size: 1.5rem !important;
    }
    .logo span{
        font-size: 2.5rem !important;
    }
    .login, .register{
        padding: 10px 30px;
        margin-right: 20px;
        font-size: 18px;
        width: 150px;
    }
    .toggler{
        margin-right: 20px;
    }
}

@media (max-width : 600px){
    .logo{
        margin-left: 20px !important;
    }
    .logo h2{
        font-size: 1rem !important;
    }
    .logo span{
        font-size: 2rem !important;
    }
    .login, .register{
        padding: 10px 30px;
        margin-right: 20px;
        font-size: 18px;
        width: 150px;
    }
    .toggler{
        margin-right: 50px;    
    }
    
}