.profile-img-section{
    background-color: #251B37;
    /* background-color: #120664; */
    width: 100vw;
    height: 40vh;
}

.profile-img{
    background-color: white;
    height: 150px;
    width: 150px;
    margin-top: 100px;
}
.profile-name{
    color: white;
    height: 150px;
    margin-top: 100px;
    padding: 40px;
    font-size: 20px;
    font-weight: 500;
}
.profile-name span{
    display: block;
}
.profile-list a{
    text-decoration: none;
    color: #251B37;
    font-size: 20px;
    font-weight: 500;
}
.active{
    background-color: #251B37;
    color: white;
}
.profile-list-item, .skill-list-item{
    grid-column: 1 !important;
}
.basic-details, .account-details, .internship-details, .education-details, .extra-curricular-activity-details, .certification-details, .tech-skills-details, .project-details{
    position: absolute;
    top: 30vh;
    width: 900px;
}
.basic-details p, .account-details p, .internship-details p, .education-details p, .extra-curricular-activity-details p, .certification-details p, .tech-skills-details p, .project-details p{
    font-size: 18px;
    font-weight: 500;
    color: #444343;
    padding: 20px;
}
.basic-details .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: none !important;
    padding: 0px !important;
}
.basic-details .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    margin-bottom: 0px;
}

.basic-details .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 0px;
}
.account-details .MuiTableRow-root{
    padding: 15px !important;
}
.activity-edit-btn, .project-edit-btn, .certification-edit-btn{
    margin-top: 35px !important;
}
.activity-edit-btn{
    height: 30px;
    float: left;
    color:'#0E5E6F' !important;
}
.intern-edit-btn{
    margin-top: 50px !important;
}
.experience-btn, .activity-btn, .certification-btn, .add-project-btn, .add-skill-btn{
    /* float: center; */
    /* margin: 30px 50px !important; */
    margin: 5% 40% !important;
}
.add-exp-dialog, .add-activity-dialog, .add-certification-dialog, .add-skill-dialog{
    padding: 50px 100px !important;
    align-content: center;
}
.add-exp-dialog .organization-name .MuiInputBase-root, .add-exp-dialog .job-title-name .MuiInputBase-root, .add-exp-dialog .location-name .MuiInputBase-root, .add-exp-dialog .sector-name .MuiInputBase-root, .add-activity-dialog .organization-name .MuiInputBase-root, .add-activity-dialog .position-title-name .MuiInputBase-root{
    width: 380px !important;
}
.progress-bar{
    background-color: #0E5E6F;
    color: aliceblue;
}
.education-edit-section, .basic-info-edit-section{
    display: flex !important;
    justify-content: space-between;
}
.education-edit-section p, .basic-info-edit-section p{
    margin-top: 20px;
}
.education-edit-btn, .basic-info-edit-btn{
    margin-top: 20px !important;
    height: 50px;
    padding: 0px 50px !important;
}
.attach-file-btn{
    width: 400px;
    height: 55px;
    display: flex !important;
    justify-content: space-between !important;
}
.certification-save-btn{
    /* width: 300px; */
    margin: 30px 0px !important;
}
.skill-btn{
    margin-left: 40% !important;
}
.add-project-btn{
    margin: 30px 25px !important;
}
.intern-duration-date-section{
    display: flex;
}
.intern-duration-date-section .date-selector{
    margin: 10px 20px 10px 0px;
}
.intern-duration-date-section .date-selector .MuiInputBase-root{
    width: 180px !important;
}
