.tpo-detail{
    background-color: white;
    padding: 10px 5px 0px 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    width: 101%;
}
.drive-feed{
    margin-top: 20px;
    /* border: 1px solid gray; */
    border-radius: 10px;
    padding: 10px 50px 10px 60px;
    background-color: #D6E5E3;
    /* background-color: #2701fd; */
}
.company-logo{
    background-color: white;
    padding: 30px;
    width: 250px;
    border-radius: 5px;
}
.company-logo img{
    width: 190px;
    height: fit-content;
    margin-bottom: 30px !important;
}
.drive-detail{
    /* padding: 50px; */
    margin: auto;
}
.drive-detail .comp-name, .skills-box .skill-requirement-text {
    font-variant: small-caps;
    font-size: 24px;
    text-align: left !important;
    margin-left: 2%;
}
.skills-box .skill-requirement-text{
    font-size: 20px;
}
.skills-box{
    background-color: white;
    width: 250px;
}
.skills-box p, .hiring-flow-heading, .drive-feed p{
    text-align: center !important;
    font-size: 18px;
    font-weight: bold;
    color: #044f6f;
    letter-spacing: 1px;
}
.tpo-detail p{
    text-align:left !important;
}
.hiring-step-box{
    font-size: 16px;
}

.skills-box .profile-designation-name{
    font-size: 16px;
    color: rgb(143, 20, 205);
    text-align: left !important;
}
.feed-tpo-bar{
    border-radius: 50%;
    margin-bottom: 5px;
    background-size: cover;
    height: 50px;
    width: 50px;
}