*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}
.registerComponent{
    /* background-image: "url(" + "../../assets/image3.jpg" + ")"; */
    color: #075a6b;
    /* padding: 50px 100px; */
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    display: grid;
    justify-content: center;
    padding: 50px;
}
.navbar{
  margin-bottom: 0px;
  height: 65px;
}
.carousel-section{
  background-color: white;
  width: 100vw;
}
.carousel-img{
  opacity: 0.8;
  backdrop-filter: blur(5px);
}
.carousel-caption p{
  text-align: left; 
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
}



.vertical-timeline-element-icon, .bounce-in {
  box-shadow: 0px 0px transparent;
  text-align: center;
}

.vertical-goals {
  --color: rgba(30, 30, 30);
  /* --bgColor: rgb(212, 247, 253); */
  --bgColor: white;
  min-height: 50vh;
  display: grid;
  align-content: center;
  gap: 2rem;
  padding: 2rem;
  font-family: "Poppins", sans-serif;
  color: var(--color);
  background: var(--bgColor);
}

ul {
  --row-gap: 2rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(80%, 90%);
  margin-inline: auto;
}

/* card */
ul li {
  /* grid-column: 2; */
  --inlineP: 2rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  width: 250px;
  grid-template-rows: min-content min-content min-content;
  transition: transform .2s;
}
ul li:hover{
  transform: scale(1.1);
}

/* .caro, .caro img{
  transition: transform .2s;
}

.caro:hover, .caro img:hover{
  transform: scale(1.2);
} */

/* date */
.date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);
  text-align: center;
  background-color: var(--accent-color);
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
  display: grid;
  place-content: center;
  position: relative;
  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}
/* date flap */
.date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}


/* title descr */
.title, .descr {
  /* background: var(--bgColor); */
  background-color: rgb(248, 248, 232);
  position: relative;
  padding-inline: 1.5rem;
}
.title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
.descr {
  padding-block-end: 1.5rem;
  font-weight: 300;
}

/* shadows */
.title::before, .descr::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.title::before {
  bottom: calc(100% + 0.125rem);
}

.descr::before {
  z-index: -1;
  bottom: 0.25rem;
}
.vertical-timeline--two-columns .vertical-timeline-element-icon{
  /* background-color: #023979 !important; */
  background-color: white !important;
  /* border: 5px solid white; */
  border: 5px solid #075a6b;
}

.vertical-timeline::before{
  background-color: #075a6b;
}

@media (min-width:360px) and (max-width : 640px){
  .carousel-caption p{
    font-size: 10px;
  }
}
@media only screen and (max-width: 1172px) {
  .vertical-timeline-element-content {
    margin-left: 8%;
  }
}

@media (max-width: 772px) {
  .vertical-timeline-element-content {
    margin-left: 14%;
  }
  .carousel-caption p {
    font-size: 10px;
  }
  .vertical-goals {
    padding: 1rem;
  }
  ul{
    width: 400px;
  }
  ul li {
    width:200px;
  }
}
@media (max-width: 480px) {
  ul {
    grid-template-columns: 1fr var(--line-w) 1fr;
    width: 70vw;
  }
  ul::before {
    grid-column: 2;
  }
  ul li:nth-child(odd) {
    grid-column: 1;
    width: 40vw;
    margin-left: 5%;
  }
  ul li:nth-child(even) {
    grid-column: 3;
    width: 40vw;
    margin-left: 5%;
  }

  /* start second card */
  ul li:nth-child(2) {
    grid-row: 2/4;
    width: 40vw;
    margin-left: 5%;
  }

  ul li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  ul li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  ul li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}


