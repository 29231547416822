@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

.content .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 100px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
    background-color: transparent !important;
}

body::-webkit-scrollbar{
    display: none !important;
}


