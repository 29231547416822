* {
    margin: 0;
    padding: 0;
}

.footer {
    background-color: #004658;
    color: #fff;
}
.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 10px;
    position: relative;
    top: -1px;
    width: 100vw;
}
.footer-wave-path {
    /* fill: #fffff2; */
    fill: white;
}
.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
    padding: 40px 15px 100px;
    position: relative;
}
.rpc{
    font-size: 25px;
    position: relative;
    bottom: 50px;
    font-weight: bold;
    letter-spacing: 2px;
    margin-left: 2.5%;
}
.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
} 

.footer-social-links {
    bottom: 0;
    height: 54px;
    position: absolute;
    right: 0;
    width: 236px;
}

.footer-social-amoeba-svg {
    height: 54px;
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    width: 236px;
}

.footer-social-amoeba-path {
    fill: #027b9a;
}

.footer-social-link {
    display: block;
    padding: 10px;
    position: absolute;
}

.hidden-link-text {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    top: 50%;
}

/* .footer-social-icon-svg {
    display: block;
} */

.footer-social-link.facebook {
    height: 26px;
    left: 0;
    top: 11px;
    width: 26px;
}
.footer-social-link.linkedin {
    height: 26px;
    left: 55px;
    top: 4px;
    width: 26px;
}
.footer-social-link.instagram {
    height: 26px;
    left: 120px;
    top: 11px;
    width: 26px;
}
.footer-social-link.youtube {
    height: 24px;
    left: 170px;
    top: 6px;
    width: 24px;
}
.social-media{
    color: white;
}

/* .footer-social-link.github {
    height: 34px;
    left: 172px;
    top: 7px;
    width: 34px;
} */

.footer-copyright {
    background-color: #027b9a;
    color: #fff;
    padding: 15px 30px;
    text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 100vw;
}

.footer-copyright-text {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}
.footer-copyright-link:hover {
    color: #fff;
    text-decoration: none;
}


.playstore-btn{
    width: 120px;
    height: 40px;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 5px;
}
.footer-btn-content{
    position:absolute; 
    bottom:30px; 
    /* display:block;  */
    justify-content: space-around !important;
}

.footer-btn-content-span{
    display:block; 
    margin-bottom:5px;
    font-weight:600; 
    letter-spacing:1px;
}


/* Media Query For different screens */
@media (min-width: 1000px){
    .footer-content {
        width: 100%;
        padding: 40px 15px 150px;
        font-size: 15px;
    }
    .footer-wave-svg {
        height: 60px;
    }
    .footer-btn-content{
        margin-bottom: 30px;
    }
    .rpc{
        font-size: 25px;
    }
}
/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media (min-width:320px) and (max-width:479px)  { 
    .footer-wave-svg {
        height: 40px;
        padding-top: 50px;
    }
}

@media (max-width: 760px) {
    .footer-content {
        width: 100%;
        padding: 40px 15px 150px;
        font-size: 12px;
    }
    .footer-wave-svg {
        height: 50px;
    }
    .footer-content-column {
        width: 25%;
    }
    .footer-social-links {
        bottom: 0;
        height: 54px;
        position: absolute;
        left: 35%;
        width: 236px;
    }
    .footer-btn-content{
        margin-bottom: 30px;
    }
    .rpc{
        font-size: 15px;
    }
}
@media (max-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .footer-content {
        width: 100%;
        padding: 40px 15px 150px;
        font-size: 12px;
    }
    .footer-wave-svg {
        height: 40px;
    }
    .footer-content-column {
        width: 25%;
    }
    .footer-social-links {
        bottom: 0;
        height: 54px;
        position: absolute;
        left: 25%;
        width: 236px;
    }
    .footer-btn-content{
        margin-bottom: 30px;
    }
    .rpc{
        font-size: 15px;
    }
}
@media (min-width:360px) and (max-width : 640px){
    .footer-wave-svg {
        height: 40px;
        padding-top: 50px;
    }
}
    
  