*{
    margin: 0;
    padding: 0;
}

.registerForm{
    /* width: fit-content; */
    padding: 10px 100px 50px;
    margin: auto;
    margin-top: 50px;
    align-self: center;
}
.MuiInputBase-root{
    margin-bottom: 10px;
    width: 400px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}
.submitBtn{
    width: 40%;
    margin: auto !important;
    margin-top: 50px !important;
}

.registerHead {
    padding: 30px;
    text-align: center;
    font-weight: bold;
    color: #044f6f;
}


@media (min-width: 445px) and (max-width : 500px){
    .registerForm{
        padding: 10px 25px 25px !important;
    }
}
@media (min-width: 500) and (max-width : 600px){
    .registerForm{
        padding: 10px 50px 50px !important;
    }
    .MuiInputBase-root{
        width:90vw !important;
    }
}
@media (min-width:360px) and (max-width : 640px){
    .registerForm{
        padding: 10px 10px 10px !important;
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        width: 90vw !important;
    }
    .MuiInputBase-root{
        width:90vw !important;
    }
}
