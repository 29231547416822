/* .upload-section{
    background-color: ;
} */

.upload-container{
    padding: 100px 50px 50px;
}
.data-img img{
    padding: 50px;
}
.upload-paper{
    padding: 60px;
    height: 350px;
    background-color: rgb(190, 255, 245) !important;
}
.about{
    /* background-color: white; */
    opacity: 0.5;
}
.about h1{
    color: white;
    position: relative;
    top: -100px;
    left: 100px;
    /* z-index: 10000; */
}
.school-icon, .tpo-icon{
    margin: auto;
    color: #004658;
}
.upload-paper p{
    color: gray !important;
    text-align: center;
    padding: 30px;
}
.upload-btn, .submit-btn{
    background-color: rgb(190, 255, 245) !important;
    border: 1px solid #004658 !important;
    color: #004658 !important;
}
.upload-btn{
    margin-right: 20px !important;
}
.upload-btn:hover, .submit-btn:hover{
    background-color: #004658 !important;
    color: white !important;
}
.sample-excel-sheets{
    position: relative;
    top: 10vh;
    margin-top: 50px;
}
.sample-student-sheets, .sample-tpo-sheets{
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border: solid 1px green;
}
.sample-student-sheets p, .sample-tpo-sheets p{
    font-size: 16px;
    color: gray;
    font-weight: 600;
    position: relative;
    top: 5px;
}










/* Profile image --------------------------------- */

.inst-background-img{
    width: 100vw;
    /* opacity: 0.8; */
    /* z-index: -10000; */
}