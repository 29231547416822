.stepper-box {
    width: 100%;
    margin: auto;
    padding: 50px 0px;
}
.steps{
    display: flex;
}
.step-data{
    width: 100%;
    margin: 5%;
}
.select-data{
    margin: 50px auto 50px;
    display: flex;
    justify-content: space-between;
    padding: 50px 50px 50px 30px;
}
.select-data-2{
    /* margin: 50px auto 50px; */
    padding: 50px 30px 50px 20px;
}
.step-btn-row{
    padding: 50px 50px 100px 50px;
}

.step-1-btn{
    display: block;
}
.step-1-data{
    display: flex;
    justify-content : space-between;
    padding: 50px 20px;
    margin: 50px auto 50px;
    width: fit-content;
}
.step-1-img{
    margin-left: 5%;
}
.step-2-img{
    margin-left: 27%;
}
.step1-input-area{
    margin: 3% 0px 0px 10%;
}
.step1-input{
    margin: 5px !important;
}
.step-1-data .dropdown{
    margin-top: 10px !important;
    margin-left: 5px !important;
    border-bottom: 1.5px solid gray;
    width: 300px;
    /* background-color: white !important; */
}
.step-data .MuiInputBase-root{
    width: 300px !important;
}
/* for multiple profile designation with respective packages */

.profile-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .package-field .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root, .step2-input-area .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    width: 220px !important;
}
.profile-add-btn{
    background-color: #075a6b !important;
    color: white;
    margin: 25px auto auto 25px !important;
}
.profile-save-btn{
    border-color: #03506f !important;
    color: #03506f !important;
    float: right;
}


.btn-light{
    background-color: white !important;
    border: none;
    color: gray;
}
.step2-input-area{
    /* width: 450px; */
    padding: 70px 0px 0px 20px !important;
}
.batch-select, .course-select, .branch-select{
    margin-top: 20px;
}
.step2-input-area .dropdown{
    border-bottom: 1.5px solid #044f6f;
}
.dropdown{
    margin-bottom: 15px;
}

.form-check-input:checked{
    background-color: #03506f;
    border-color: #03506f;
}
.step-3-data {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(4, 79, 111) !important;
}
.step-3-data p{
    padding: 50px;
}
.next-step-btn, .back-step-btn{
    height: fit-content;
    margin-top: 25% !important;
}
.successfully-created p{
    font-size: 18px;
    font-weight: 500;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    grid-column: 1 !important;
}