.skillbar{
    height: 40px !important;
}
.skillbar-title{
    width: 180px !important;
}
.skillbar-bar{
    position: unset !important;
}
.skillbar-title span{
    padding: 0px !important;
    text-align: center;
    margin-top: 8%;
    height: fit-content !important;
    font-size: 16px;
    vertical-align: middle;
}
.skillbar-percent{
    display: none !important;
}