*{
    margin: 0;
    padding: 0;
}
body{
    background-color: white;
}

/* Dashboard Children */

.child-component{
    margin-top: 60px;
}


/* Add institution  */
.registerF{
    width: fit-content;
    padding: 10px 100px 50px;
    margin: auto;
    margin-top: 50px;
}
.MuiInputBase-root{
    margin-bottom: 10px;
    width: 400px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    border-radius: 8px !important;
}
.submitBtn{
    width: 30%;
    margin: auto !important;
    margin-top: 50px !important;
}

.registerHead {
    /* padding: 30px;
    text-align: center;
    font-weight: bold; */
    color: #044f6f;
    margin: 30px auto 50px auto;
}

/* joining request */
.request-paper{
    padding: 50px;
    width: 90%;
    margin: auto;
    margin-top: 50px;

}

/* DASHBOARD CONTENT  */
.summarized-detail{
    background-color: white;
    padding: 20px;
}


/* manage users styles */
.user-role-heading{
    font-size: 18px;
    font-weight: bold;
    color: #03374d;
    font-variant: small-caps;
    background-color: rgb(191, 251, 251);
    width: fit-content;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}
.outer-paper{
    padding: 20px 50px 50px 50px;
    margin-top: 50px;
}
.inner-paper{
    padding: 30px;
    margin-top: 50px;
}

.users-delete-btn{
    float: right;
    background-color: #044f6f !important;
}

ul li:nth-child(even){
    grid-column: 1;
}
.pie-chart-list-items{
    color:#344D67; 
    display:flex;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 600 !important;
}
.pie-chart-list-content{
    width:15px;
    height:15px;  
    border-radius:5px; 
    display:flex !important;
    margin-right: 10px;
}
.pie-chart-details{
    position: absolute;
    top:40%;
    right:0%;
}